html,
body,
#root {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

/* Global properties inherited by all */
body {
	font-family: "Nunito Regular", "Helvetica Neue";
	background-color: #c1c1c1;
}

h1,
h2,
h3 {
	text-align: center;
}

/* Main container for aside and canvas */
.container-row {
	display: flex;
	height: 100%;
}

/* Shared properties for all elements that stand out from the background */
.viewport,
aside {
	margin: 10px;
	box-shadow: 0px 40px 20px 0px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	background-color: #f4f4f4;
}

aside {
	margin-right: 0px;
	padding-inline: 25px;
	width: 20em;
}

.viewport {
	padding: 0px;
	height: auto !important;
}

@font-face {
	font-family: "Nunito Regular";
	src: url("./Nunito-Regular.ttf");
}
